<template>
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/experience_bg.png')+')'}">
    <navigation :msg="'用户体验'"></navigation>
		<div class="min">
			<div v-for="(item,index) in lists " :key="index" @click="distribution(item.clickOnThe)">{{ item.name }}</div>
		</div>
		<div class="modalDialog" v-if="modalDialog">
			<div class="claimedCredit">
				<div class="head">
					<p>{{ description }}</p>
					<p class="close" @click="shutDown">X</p>
				</div>
				<div class="putInmin" v-if="statusinof.applyFor">
					<el-form ref="form"  label-width="100px">
						<el-form-item label="申请类型" prop="region">
							<el-select v-model="region" 
							placeholder="请选择申请类型"
							:popper-append-to-body="false"
							@change="tapindex" style="width: 80%;">
							<el-option v-for="item in labels" :label="item.name" :value="item.typeId" :key="item.typeId"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="申请项目"  prop="regionins" >
							<el-select v-model="regionins" 
							placeholder="请选择申请项目"
							:popper-append-to-body="false"
							@change="switchType" 
							style="width: 80%;">
							<el-option v-for="( item , index ) in values" :label="item.desc" :value="index" :key="item.id"
							></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="分值" prop="numerical">
							<el-input-number v-if="values[indexs].evaluationWay == 3" v-model="values[indexs].value"  :min="values[indexs].leastValue" :max="values[indexs].maxValue" style="width: 50%;"></el-input-number>
							<div v-if="values[indexs].evaluationWay  == 1 " style="text-align: center;"> {{ values[indexs].value }} 分</div>
								<el-radio-group v-model="values[indexs].value" v-if="values[indexs].evaluationWay  == 2">
								<el-radio v-for=" item in section " :key="item"  :label="item">{{ item }} 分</el-radio>
								</el-radio-group>
						</el-form-item>
						<el-button type="primary" @click="submitclaimedCredit('form')">申请</el-button>
						<el-button class="primarybut" type="primary" @click="shutDown" >取消</el-button>
					</el-form>
				</div>
				<div class="putInmin" v-if="statusinof.userProfile">
					<div><span>用户名称:</span><span>{{ userinfo.memberName }}</span></div>
					<div><span>用户身份:</span><span>{{ userinfo.familyRelation }}</span></div>
					<div><span>用户积分:</span><span>{{ userinfo.family.familyBaseScore+userinfo.family.familyScore }} 分</span></div>
					<div><span>所属家庭:</span><span>{{ userinfo.family.familyName }}</span></div>
				</div>
				<div class="putInmin" v-if="statusinof.integralView">
					<div class="main">
						<div v-if="userinfo.integralRecords.length>0">
							<div v-for="( item , index ) in userinfo.integralRecords" class="card" :key="index" :class="{points:0>item.integral}">
								{{ item.evaluationDesc }}
								<div>{{ item.createTime }}</div>
								<div class="score">{{ item.integral>0? '+':'' }} {{ item.integral }} 分</div>
							</div>
						</div>
						<div v-if ="userinfo.integralRecords == '' ">
							暂无数据
						</div>
						<div style="height: 30px ;"></div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
import  navigation  from "@/components/navigation";
import axios from 'axios'
import { root } from '@/config';
export default {
    components:{
        navigation
    },
    data() {
        return {
			lists:[
				{
					name:'用户信息',
					clickOnThe:'userProfile'
				},{
					name:'积分申请',
					clickOnThe:'claimedCredit'
				},{
					name:'排行榜',
					clickOnThe:'list'
				},{
					name:'积分查看',
					clickOnThe:'integralView'
				},
				],
			modalDialog:false,
			statusinof:{
				applyFor:false,
				userProfile:false,
				integralView:false
			},
			userinfo:{},
			values:[], // 申请项目
			labels:[], // 申请类型
			indexs:0,
			egion:"",
			regionins:"",
			numerical:0,
			region:{},
			description:''
        }
    },
	mounted(){
		this.userinfo = JSON.parse(window.sessionStorage.getItem('userinfo-qoe')) 
		// console.log(this.userinfo);
	},
	watch: {
		values(){
			//对分数进行筛选
			if (this.values[this.indexs].section) {
				this.section = this.values[this.indexs].section.split(',')
				this.values[this.indexs].value = this.section[this.classs]*1
			}
		}
    },
    methods: {
		shutDown(){
			this.modalDialog = false
			this.statusinof={
				applyFor:false,
				userProfile:false,
				integralView:false
			}
		},
		getuserinfo(){
			const vm =this
        axios.get(root+'pc/interaction/userInfo')
        .then(function (response) {
			console.log(response.data.data);
			let aa = JSON.stringify(response.data.data) 
			window.sessionStorage.setItem('userinfo-qoe',aa)
			})
			.catch(function (error) {
			console.log(error);
			});
		},
		userProfile(){
			console.log('用户信息');
			this.modalDialog = true
			this.statusinof.userProfile = true
			this.description ='用户信息'
			this.userinfo = JSON.parse(window.sessionStorage.getItem('userinfo-qoe'))
		},
		claimedCredit(){
			this.genre(this.userinfo.deptId)
			this.modalDialog = true
			this.statusinof.applyFor = true
			this.description ='积分申请'
		},
		list(){
			this.$router.push('/rankList')
		},
		integralView(){
			console.log('积分查看');
			this.modalDialog = true
			this.statusinof.integralView = true
			this.description ='积分查看'
			this.userinfo = JSON.parse(window.sessionStorage.getItem('userinfo-qoe'))
		},
		distribution(val){
			if (val=='userProfile') {
				this.userProfile()
			}else if (val=='claimedCredit') {
				this.claimedCredit()
			}else if (val=='list') {
				this.list()
			}else if (val=='integralView') {
				this.integralView()
			}
		},
		//获取评估类型列表
		genre(deptId){
			const vm = this 
			axios.get(`${root}pc/interaction/gbTypeList/${deptId}`).then(function (response) {
				// 处理成功情况
				vm.labels = response.data.data
				vm.region = response.data.data[0].name
				vm.evaluation(response.data.data[0].typeId)
			})
			.catch(function (error) {
				// 处理错误情况
				console.log(error);
			})
		},
		// 获取评估方式列表
		evaluation(typeId){
			const vm = this 
			axios.get(`${root}pc/interaction/criterialist/${typeId}`)
			.then(function (response) {
				// 处理成功情况
				vm.values = response.data.data.filter(res=>res.sign == '1')
				vm.regionins =  response.data.data[0].desc
			})
			.catch(function (error) {
				// 处理错误情况
				console.log(error);
			})
		},
		//申请接口
		submitclaimedCredit(){
			const vm = this 
			let datas = {
					familyId:vm.userinfo.familyId,
					userId:vm.userinfo.id,
					ownerName:vm.userinfo.memberName,
					integral:vm.values[vm.indexs].value,
					remark:vm.values[vm.indexs].desc,
					ruleId:vm.values[vm.indexs].id,
					deptId:vm.userinfo.deptId,
					ownerId:vm.userinfo.id,
					ownerAdder:vm.userinfo.address,
					status:"1"
				}
				console.log(datas);
			// 向给定ID的用户发起请求
			axios.post(`${root}pc/interaction/integral`,datas)
			.then(function (response) {
				if (response.data.code == 200) {
					vm.$message({
						message: '积分申请成功',
						type: 'success'
					});
					vm.getuserinfo()
					vm.modalDialog = false
					vm.statusinof.applyFor = false
				}else{
					this.$message.error(response.data.msg);
				} 
			})
			.catch(function (error) {
				// 处理错误情况
				console.log(error);
			})
		},
		tapindex(e){
                this.evaluation(e)
            },
		switchType(e){
			this.indexs = e
		}
    },
}
</script>
<style lang="scss" scoped>
    .wrapper{
            width: 100%;
            height: 100%;
            background: no-repeat; 
            background-size: 100% 100%;
            background-position: center;
            position: relative;
            .navigation{
                background-color: rgba(255, 255, 255,0);
                position: absolute;
                top: 10%;
            }
			.min{
				position: absolute;
				top: 30%;
				left: 25%;
				width: 50%;
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				div{
					width: 40%;
					height: 100px;
					background-color: #e2c18e;
					font-size: 30px;
					text-align: center;
					line-height: 100px;
					margin: 30px 0;
					padding: 10px;
					cursor:pointer;
				}
			}
			.modalDialog{
				background-color: rgba(0, 0, 0,0.5);
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				.claimedCredit{
					background-color: #fff;
					width: 500px;
					height: 500px;
					.head{
						background-color: #eb622e;
						height: 40px;
						color: #fff;
						font-size: 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 10px;
						.close{
							background-color: #f7d3d3;
							width: 20px;
							text-align: center;
						}
					}
				.putInmin{
					text-align: center;
					padding: 20px;
					height: 80%;
					overflow-x: hidden;
					overflow-y: scroll;
					.el-form-item{
						margin: 50px 0;
					}
					.el-select:focus{
						border:2px solid #eb622e;
					}
					.el-button{
						background-color: #eb622e;
						border: none;
						width: 30%;
						border-radius: 2px;
						margin: 0 20px;
					}
					.primarybut{
						background-color: #cfcfcf;
						color: #000;
					}
					div{
						span{
							display: inline-block;
							width: 200px;
							text-align: left;
							margin: 25px 0;
							font-size: 20px;
						}
					}
					.main{
						width: 100%;
						text-align: left;
					}
					.card{
						background-color: #f4f4f4;
						width: 80%;
						margin: 0 auto;
						margin-top: 30px;
						padding: 10px;
						position: relative;
						border-radius: 5px;
						line-height: 35px;
					}
					.score{
						position: absolute;
						top: 30px ;
						right: 30px;
					}
					.points{
						background-color: #ffd5d5;
					}
					.card:hover,.points:hover{
						box-shadow: 0px 0px 30px 0px #cfcfcf;
					}
					.longer{
						height: 500px;
					}
				}
				}
			}
        }
		.el-select ::v-deep .el-input .el-input__inner {
			border-color: #cbcbcb  !important;
		}
		.el-select-dropdown__item.selected{
			color: #ec632e ;
		}
</style>