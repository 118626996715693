<template>
  <div class="home">
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/index_bg.png')+')'}">
      <div class="buttons">
        <div v-for="(item,index) in buttons " :key="index" class="buttonmin" @click="gotos(item.goto)">
          <div class="buttonicon">
            <img :src="$staticURL(item.url)">
          </div>
          <div class="buttontext">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { root } from '@/config';
export default {
  name: 'HomeView',
  data() {
    return {
      buttons:[{
        url:'icon/oschina.png',
        name:'社区介绍',
        goto:'/community'
      },{
        url:'icon/nparticle.png',
        name:'系统介绍',
        goto:'/lineage'
      },{
        url:'icon/useGuide.png',
        name:'使用指南',
        goto:'/userManual'
      },{
        url:'icon/rankList.png',
        name:'积分排行',
        goto:'/rankList'
      },{
        url:'icon/user.png',
        name:'用户体验',
        goto:'/experience'
      }]
    }
  },
  mounted(){
    this.hello()
  },
  methods: {
      hello(){
        axios.get(root+'pc/interaction/userInfo')
        .then(function (response) {
          console.log(response.data.data);
          let aa = JSON.stringify(response.data.data) 
          window.sessionStorage.setItem('userinfo-qoe',aa)
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      gotos(url){
        this.$router.push(url)
      }
  },
}
</script>
<style lang="scss" scoped>
  .home{
    height: 100%;
    .wrapper{
        width: 100%;
        height: 100%;
        background: no-repeat; 
        background-size: 100% 100%;
        background-position: center;
        position: relative;
        content-visibility: auto ;
        .buttons{
          display: flex;
          width: 50%;
          justify-content: space-evenly;
          position: absolute;
          right: 10%;
          bottom: 43%;
          .buttonmin{
            cursor:pointer;
            .buttonicon{
              height: 50px;
              width: 50px;
              border: 5px solid white;
              border-radius: 3px;
              padding: 10px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .buttontext{
              width: 80px;
              text-align: center;
              font-size: 18px;
              color: #fff;
              margin-top: 20px;
            }
            &:hover {
              .buttonicon,.buttontext{
                background-color: #ca3b28;
              }
            }
          }
        }
        }
  }
</style>
