<template>
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/userManual_bg.png')+')'}">
    <navigation :msg="'积分规则'"></navigation>
    </div>
</template>
<script>
import  navigation  from "@/components/navigation";
export default {
    components:{
        navigation
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
    .wrapper{
            width: 100%;
            height: 100%;
            background: no-repeat; 
            background-size: 100% 100%;
            background-position: center;
            position: relative;
            content-visibility: auto ;
            .navigation{
                background-color: rgba(255, 255, 255,0);
                position: absolute;
                top: 10%;
            }
        }
</style>