const env = "product";
module.exports = {
    root: function() {
        let url;
        switch (env) {
            case "dev":
                url = 'http://192.168.31.189:8080/app/v1/'
                break;
            case "test":
                url = "http://192.168.31.40:8846/app/v1/"
                break;
            case "product": 
                url = "https://bank-api.junwuculture.cn/"
                break; 
            default:
                url = ""
                break;
        }
        return url; 
    }(),
	// 后台图片地址
    afterURL:(url) => {
        // return `http://192.168.31.40:8846${url}`; 
        return `https://cms.junwuculture.cn/prod-api/app/v1${url}`; 
    },
    staticURL: (url)=>{
        return `${require(`@/static/${url}`)}`
    },
    uploadURL: (url)=>{
        return `https://com/${url}`
    },
}