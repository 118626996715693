<template>
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/community_bg.png')+')'}">
    <img :src="$staticURL('bg/lineage_bg.png')" class="topimg">
    <p class="headline">{{ text }}</p>
        <navigation :msg="'系统介绍'"></navigation>
        <div class="mintexts">
            <p class="p1">{{ introduce0 }}</p>
            <p class="p2">{{ introduce1 }}</p>
            <p class="p3">{{ introduce2 }}</p>
        </div>
    </div>
</template>
<script>
import  navigation  from "@/components/navigation";
export default {
    components:{
        navigation
    },
    data() {
        return {
            text:"党建统领乡村信用体系",
            introduce0: "以党建为统领，结合乡村产业发展、人居环境整治、乡村文明建设、社会治安防控等重点工作，突出社会公德、职业道德、家庭美德、个人品德，把涉及基层治理的各项内容细化为具体的信用积分，以数字化小程序为载体，采取诚信加分和失信减分的形式，对居民的日常行为进行评价，给予相应的精神奖励和物质奖励，着力构建组织强、民风和、环境优、城乡美、产业兴的城乡发展新格局。",
            introduce1:"幸福超市",
            introduce2:"德育积分"
        }
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
    .wrapper{
            width: 100%;
            height: 100%;
            background: no-repeat; 
            background-size: 100% 100%;
            background-position: center;
            position: relative;
            .navigation{
                margin-top: -10px;
            }
            .headline{
                z-index: 999;
                position: absolute;
                top: 13%;
                font-size: 60px;
                color: #fff;
                width: 100%;
                text-align: center;
            }
            .topimg{
                z-index: 1;
                width: 100%;
                height: 40%;
            }
            .mintexts{
                text-indent: 2em;
                color: #fff;
                font-size: 20px;
                padding: 20px;
                position: absolute;
                height: 40%;
                letter-spacing:5px;
                line-height: 35px;
                width: 90%;
                text-align: justify;
                margin-left: 2%;
                // margin-top: 1%;
                .p1{
                    width: 70%;
                    height: 100%;
                    padding-top: 50px;
                }
                .p2{
                    writing-mode:vertical-rl;
                    position: absolute;
                    top: 15%;
                    right: 9%;
                    font-size: 30px;
                }
                .p3{
                    writing-mode:vertical-rl;
                    position: absolute;
                    text-align: center;
                    top: 40%;
                    right: 6%;
                    font-size: 30px;
                }
            }
        }
</style>