import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {staticURL,uploadURL,afterURL} from '@/config'
Vue.prototype.$staticURL = staticURL;
Vue.prototype.$uploadURL = uploadURL;
Vue.prototype.$afterURL = afterURL;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$bus = new Vue();// 在vue实例原型上添加一个$bus这样的一个对象
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
