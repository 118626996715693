<template>
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/community_bg.png')+')'}">
    <img :src="$staticURL('bg/dongmaiyao.jpg')" class="topimg">
    <p class="headline">{{ text }}</p>
        <navigation :msg="'社区介绍'"></navigation>
        <div class="mintexts">
            <p class="p1">{{ introduce0 }}</p>
            <p class="p2">{{ introduce1 }}</p>
            <p class="p3">{{ introduce2 }}</p>
            <p class="p4" @click="gotos">查看详情>>></p>
        </div>
    </div>
</template>
<script>
import  navigation  from "@/components/navigation";
export default {
    components:{
        navigation
    },
    data() {
        return {
            text:"党建统领乡村信用体系",
            introduce0: "崂山区沙子口街道东麦窑村位于崂山脚下，流清湾畔，三面环山，南临大海，地处南线进入崂山风景区的必经之路，占地1090亩，共有居民135户，460人，党员29名。近年来，党支部坚持“围绕产业抓党建，抓好党建促发展”，走出了一条以特色农旅一体化发展带富带强村集体、推动乡村全面振兴的发展路径。先后荣获“中国乡村旅游模范村”“中国美丽休闲乡村”等荣誉称号，入选首批山东省景区化村庄。村党支部被评为“山东省干事创业好班子”、“山东省先进基层党组织”，并于2021年被评为“全国先进基层党组织”。",
            introduce1:"幸福超市",
            introduce2:"德育积分"
        }
    },
    methods: {
        gotos(){
            this.$router.push('/holdUp')
        } 
    },
}
</script>
<style lang="scss" scoped>
    .wrapper{
            content-visibility: auto ;
            width: 100%;
            height: 100%;
            background: no-repeat; 
            background-size: 100% 100%;
            background-position: center;
            position: relative;
            .navigation{
                margin-top: -10px;
            }
            .headline{
                z-index: 999;
                position: absolute;
                top: 13%;
                font-size: 60px;
                color: #fff;
                width: 100%;
                text-align: center;
            }
            .topimg{
                z-index: 1;
                width: 100%;
                height: 40%;
            }
            .mintexts{
                text-indent: 2em;
                color: #fff;
                font-size: 20px;
                padding: 20px;
                position: absolute;
                height: 40%;
                letter-spacing:5px;
                line-height: 35px;
                width: 90%;
                text-align: justify;
                margin-left: 2%;
                // margin-top: 1%;
                .p1{
                    width: 70%;
                    height: 100%;
                    padding-top: 50px;
                }
                .p2{
                    writing-mode:vertical-rl;
                    position: absolute;
                    top: 15%;
                    right: 9%;
                    font-size: 30px;
                }
                .p3{
                    writing-mode:vertical-rl;
                    position: absolute;
                    text-align: center;
                    top: 40%;
                    right: 6%;
                    font-size: 30px;
                }
                .p4{
                    text-indent: 0em;
                    display: inline-block;
                    // width: 200px;
                    // height: 30px;
                    position: fixed;
                    bottom: 10px;
                    right: 33%;
                    font-size: 15px;
                    // border-radius: 20px;
                    text-align: right;
                    // background-color: aqua;
                }
            }
        }
</style>