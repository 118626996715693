<template>
    <div class="wrapper" :style="{backgroundImage: 'url('+$staticURL('bg/rankList_bg.png')+')'}">
    <navigation :msg="'积分排行'"></navigation>
    <div class="main" :style="{backgroundImage: 'url('+$staticURL('icon/huabiao.png')+')'}">
        <p class="header">积分排行榜</p>
        <div class="min">
            <img :src="$staticURL('icon/onLeft.png')" alt="" class="onLeft" @click="turnPageon(1)">
            <div class="chemistry">
                <div  v-for="item in topTen " :key="item.id">
                    <span class="rank" v-if="item.rownum==1"><img :src="$staticURL('icon/medal.png')"></span>
                    <span class="rank" v-else-if="item.rownum==2"><img :src="$staticURL('icon/sinDaWoon.png')"></span>
                    <span class="rank" v-else-if="item.rownum==3"><img :src="$staticURL('icon/brassPlate.png')"></span>
                    <span class="rank" v-else>{{ item.rownum }}</span> <span> {{ item.familyOwner }} </span>{{ item.score }}分
                </div>
            </div>
            <p class="middleLine"></p>
            <div class="chemistry" :style="{ width:afterTen ==''?'200px':''}">
                <div  v-for="item in afterTen " :key="item.id">
                    <span class="rank" v-if="item.rownum==1"><img :src="$staticURL('icon/medal.png')"></span>
                    <span class="rank" v-else-if="item.rownum==2"><img :src="$staticURL('icon/sinDaWoon.png')"></span>
                    <span class="rank" v-else-if="item.rownum==3"><img :src="$staticURL('icon/brassPlate.png')"></span>
                    <span class="rank" v-else>{{ item.rownum }}</span> <span> {{ item.familyOwner }} </span>{{ item.score }}分
                </div>
            </div>
            <img :src="$staticURL('icon/onRight.png')" alt="" class="onRight" @click="turnPageup(1)">
        </div>
    </div>
    </div>
</template>
<script>
import  navigation  from "@/components/navigation";
import axios from 'axios'
import { root } from '@/config';
export default {
    components:{
        navigation
    },
    data() {
        return {
            messagelist:[], // 数据
            pageIndex: 1, // 第几页
            pageSize: 20, // 每页几条数据
            totalPages:'', //总页数
            topTen:[],  // 前十
            afterTen:[] // 后十
        }
    },
    mounted(){
        this.hello()
    },
    methods: {
        hello(){
            const vm = this
            let deptId = JSON.parse(window.sessionStorage.getItem('userinfo-qoe')).deptId 
            axios.get(root+`pc/interaction/rankinglist/${deptId}`).then(function (response) {
                let { data,msg,code } = response.data
                if (code == 200) {
                    console.log(data);
                    vm.messagelist = data
                    vm.getShowTableData()
                    //判断当前有多少分页
                    vm.totalPages = Math.ceil(vm.messagelist.length / vm.pageSize)
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        //截取数据进行分页
        getShowTableData() {
            // 5. 获取截取开始索引
            let begin = (this.pageIndex - 1) * this.pageSize;
            // 6. 获取截取结束索引
            let end = this.pageIndex * this.pageSize;
            // 7. 通过索引去截取，从而展示
            let showTableData = this.messagelist.slice(begin, end);
            this.topTen = showTableData.slice(0,10)
            this.afterTen = showTableData.slice(10,20)
        },
        // 向下翻页
        turnPageup(val) {
            if (this.pageIndex < this.totalPages) {
                this.pageIndex = this.pageIndex+val;
                this.getShowTableData();
            }else{
                alert("当前是最后一页")
                // this.$utils.toast('当前是最后一页');
            }
        },
        //向上翻页
        turnPageon(val) {
            if (this.pageIndex!=1) {
                this.pageIndex = this.pageIndex-val;
                this.getShowTableData();
            }else {
                alert("当前是第一页")
                // this.$utils.toast('当前是第一页');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .wrapper{
            width: 100%;
            height: 100%;
            background: no-repeat; 
            background-size: 100% 100%;
            background-position: center;
            position: relative;
            content-visibility: auto ;
            .navigation{
                background-color: rgba(255, 255, 255,0);
                position: absolute;
                top: 8%;
            }
            .main{
                border-radius: 30px;
                width: 550px;
                height: 550px;
                position: absolute;
                top: 20%;
                right: 23%;
                background: no-repeat;
                background-size: 60% 80%;
                background-position: 53% -170% ;
                background-color: white;
                .header{
                    text-align: center;
                    font-size: 30px;
                    margin-top: 20px;
                    color: #fff;
                }
                .min{
                    display: flex;
                    justify-content: space-around;
                    padding: 10px;
                    .middleLine{
                        width: 2px;
                        height: 400px;
                        background-color: #f6c2a8;
                        position: absolute;
                        left: 50%;
                        top: 20%;
                    }
                    .chemistry{
                        margin-top: 40px;
                        div{
                            display: flex;
                            margin: 8px 0;
                            span{
                                display: inline-block;
                                width: 130px;
                                text-align: center;
                            }
                            .rank{
                                width: 30px;
                                height: 30px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .onLeft{
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                    }
                    .onRight{
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: 50%;
                        right: 0;
                    }
                }
                
            }
        }
</style>