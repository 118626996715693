import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入 HomeView（名字） 从 哪引进来的
import HomeView from '../views/HomeView.vue'
// 配置路由顺序
// ①引入文件
// ②配置路由
import community from '../views/community.vue'
import lineage from '../views/lineage.vue'
import userManual from '../views/userManual.vue'
import rankList from '../views/rankList.vue'
import experience from '../views/experience.vue'
import holdUp from '../views/holdUp.vue'
Vue.use(VueRouter)

// 配置路由
const routes = [
  {
    // '/' 代表默认地址
    path: '/',// 访问地址
    name: 'home',
    component: HomeView// 访问路由显示的组件
  },
  {
    path: '/community',// 访问地址
    name: 'community',
    component: community// 访问路由显示的组件
  },
  {
    path: '/lineage',// 访问地址
    name: 'lineage',
    component: lineage// 访问路由显示的组件
  },
  {
    path: '/userManual',// 访问地址
    name: 'userManual',
    component: userManual// 访问路由显示的组件
  },
  {
    path: '/rankList',// 访问地址
    name: 'rankList',
    component: rankList// 访问路由显示的组件
  },
  {
    path: '/experience',// 访问地址
    name: 'experience',
    component: experience// 访问路由显示的组件
  },
  {
    path: '/holdUp',// 访问地址
    name: 'holdUp',
    component: holdUp// 访问路由显示的组件
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
