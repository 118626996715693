<template>
  <div class="navigation">
    <div class="min">
      <div v-for="(item,index) in buttons" :key="index" @click="gotos(item.goto)" :class="{vary:msg==item.name}">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'navigation',
  props: ["msg"],
  data () {
    return{
      buttons:[{
        url:'icon/oschina.png',
        name:'首页',
        goto:'/'
      },{
        url:'icon/oschina.png',
        name:'社区介绍',
        goto:'/community'
      },{
        url:'icon/nparticle.png',
        name:'系统介绍',
        goto:'/lineage'
      },{
        url:'icon/useGuide.png',
        name:'积分规则',
        goto:'/userManual'
      },{
        url:'icon/rankList.png',
        name:'积分排行',
        goto:'/rankList'
      },{
        url:'icon/user.png',
        name:'用户体验',
        goto:'/experience'
      }]
    }
  },
  methods: {
    gotos(url){
        this.$router.push(url)
      }
  }
}
</script>
<style scoped lang="scss">
.navigation{
  width: 100%;
  background-color: #e37d2b;
  .min{
    width: 70%;
    display: flex;
    font-size: 20px;
    justify-content: space-around;
    padding: 15px;
    color: #fff;
    div{
      cursor:pointer;
      &:hover{
      border-bottom: 5px solid #e8452f ;
      padding-bottom: 5px;
    }
    }
  }
}
  .vary{
    border-bottom: 5px solid #e8452f ;
    padding-bottom: 5px;
  }
</style>
